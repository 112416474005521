import SquareUserRoundIcon from "virtual:icons/lucide/square-user-round";
import { Link, useLoaderData } from "@remix-run/react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { $path } from "remix-routes";
import { useOnClickOutside } from "usehooks-ts";

import { useQueryParams } from "#app/hooks/use-query-params";
import type { loader } from "#app/routes/_app+/_index";
import { cx } from "#cva.config";
import { Button } from "../button";
import { CharacterCard } from "../character-card";
import { Toggle } from "../toggle";

export function FindYourCharacterSection() {
	const { filters, tags, characters } = useLoaderData<typeof loader>();
	const { handleQueryChange } = useQueryParams();
	const { t } = useTranslation("common");
	const { t: t2 } = useTranslation("home");
	const [selectedCategory, setSelectedCategory] = useState("");
	const wrapperRef = useRef<HTMLDivElement>(null);
	const speechFormMenu = useRef<HTMLDivElement>(null);
	const [isSpeakformOpen, setSpeakformOpen] = useState(false);
	useOnClickOutside(wrapperRef as React.RefObject<HTMLDivElement>, () => setSelectedCategory(""));
	useOnClickOutside(speechFormMenu, () => setSpeakformOpen(false));

	const categories = [...new Set(tags.map((tag) => tag.category))];
	const selectedCategories = [
		...new Set(tags.filter((tag) => filters.tags.includes(tag.slug)).map((tag) => tag.category)),
	];

	return (
		<section className="mb-16 py-10 min-h-fit w-auto">
			<h2 className="mx-auto my-10 mb-0 max-w-screen-lg shrink-0 truncate text-center font-semibold text-3xl text-gray-700 tracking-tight md:my-0 md:mb-3 dark:text-zinc-100">
				<span className="text-primary">{t("home:findYourCharacter.title1")}</span> {t("home:findYourCharacter.title2")}
			</h2>

			<div className="mt-4 mb-10 flex flex-col items-center justify-center gap-3 md:flex-row" ref={wrapperRef}>
				{categories.map((cat) => {
					return (
						<div className="md:!w-auto relative flex w-full flex-col items-center justify-center gap-3" key={cat}>
							<Toggle
								className={cx(
									"h-10 w-full",
									selectedCategories.includes(cat) &&
										"!border-red-500 bg-gradient-to-br from-red-400 to-red-600 text-white",
								)}
								onClick={() => {
									selectedCategory === cat ? setSelectedCategory("") : setSelectedCategory(cat);
								}}
							>
								<span>{t(`${cat}`)}</span>
							</Toggle>

							{selectedCategory === cat ? (
								<div className="absolute top-11 w-80 inline z-50 overflow-auto justify-center gap-3 rounded-md border-white bg-background pt-3 pb-0.5 shadow-md max-h-64">
									{tags
										.filter((tag) => tag.category === cat)
										.map((tag) => (
											<div className="w-36 float-left mb-3.5 ml-2.5" key={tag.id}>
												<Toggle
													className={cx(
														"h-10 w-full",
														filters.tags.includes(tag.slug) &&
															"!border-red-500 bg-gradient-to-br from-red-400 to-red-600 text-white",
													)}
													onPressedChange={() => {
														handleQueryChange({
															key: "tags[]",
															value: tag.slug,
															mustDelete: filters.tags?.includes(tag.slug),
															isArray: true,
														});
													}}
													pressed={filters.tags?.includes(tag.slug)}
												>
													<span>{t(`${tag.name}`)}</span>
												</Toggle>
											</div>
										))}
								</div>
							) : null}
						</div>
					);
				})}

				<div className="md:!w-auto relative flex w-full flex-col items-center justify-center gap-3">
					<Toggle
						className={cx(
							"h-10 w-full",
							filters.speechform &&
								filters.speechform !== "all" &&
								"!border-red-500 bg-gradient-to-br from-red-400 to-red-600 text-white",
						)}
						onClick={() => {
							setSpeakformOpen(!isSpeakformOpen);
							setSelectedCategory("");
						}}
					>
						Speechform
					</Toggle>

					{isSpeakformOpen ? (
						<div
							ref={speechFormMenu}
							className="absolute top-11 w-80 inline z-50 overflow-auto justify-center gap-3 rounded-md border-white bg-background pt-3 pb-0.5 shadow-md max-h-64"
						>
							<div className="w-36 float-left mb-3.5 ml-2.5">
								<Toggle
									className={cx(
										"h-10 w-full",
										filters.speechform &&
											filters.speechform === "first person" &&
											"!border-red-500 bg-gradient-to-br from-red-400 to-red-600 text-white",
									)}
									onPressedChange={() => {
										handleQueryChange({
											key: "speechform",
											value: "first person",
											mustDelete: filters.speechform?.includes("first person"),
										});
									}}
									pressed={filters.speechform?.includes("first person")}
								>
									<span>First Person</span>
								</Toggle>
							</div>
							<div className="w-36 float-left mb-3.5 ml-2.5">
								<Toggle
									className={cx(
										"h-10 w-full",
										filters.speechform &&
											filters.speechform === "third person" &&
											"!border-red-500 bg-gradient-to-br from-red-400 to-red-600 text-white",
									)}
									onPressedChange={() => {
										handleQueryChange({
											key: "speechform",
											value: "third person",
											mustDelete: filters.speechform?.includes("third person"),
										});
									}}
									pressed={filters.speechform?.includes("third person")}
								>
									<span>Third Person</span>
								</Toggle>
							</div>
						</div>
					) : null}
				</div>
			</div>

			<div>
				{characters.length ? (
					<div className="relative grid w-full grid-cols-2 gap-2 lg:grid-cols-[repeat(auto-fill,_minmax(300px,_1fr))] max-[370px]:grid-cols-1 sm:grid-cols-3 sm:gap-4">
						{characters.map((character) => (
							<Link key={character.id} to={`/characters/${character.id}`}>
								<CharacterCard character={character} />
							</Link>
						))}
					</div>
				) : (
					<div className="flex flex-1 flex-col items-center justify-center gap-2 py-32">
						<SquareUserRoundIcon className="size-12 opacity-60" />
						<p className="text-lg opacity-60">{t2("findYourCharacter.noCharactersFound")}</p>
						<Button asChild>
							<Link to={$path("/characters/create")}>
								<span>{t2("findYourCharacter.createFirstCharacter")}</span>
							</Link>
						</Button>
					</div>
				)}
			</div>
		</section>
	);
}
